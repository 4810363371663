import axios from 'axios'
import { gql } from 'apollo-boost'
import { environment } from '@mortgage-pos/ui/env'
import { getCSRFToken } from '@mortgage-pos/utils'
import { borrowerPortalLoginUrl } from '@mortgage-pos/data'
import axiosRetry from 'axios-retry'

const http = axios.create({
  withCredentials: true,
})

export const httpBorrowerPortal = axios.create({
  withCredentials: true,
  baseURL: environment.borrowerPortal.apiBaseUrl,
})

export default http

export const cohesionHeaders = () => {
  // @ts-ignore
  const cohesionIds = window.tagular && window.tagular('getAliasSet')
  if (!cohesionIds) {
    return {}
  }

  const returnHeaders = {
    'cohesion-anonymous-id': cohesionIds.anonymousId,
    'cohesion-session-id': cohesionIds.sessionId,
    'cohesion-instance-id': cohesionIds.instanceId,
    'cohesion-tenant-id': cohesionIds.tenantId,
    'cohesion-transit-id': cohesionIds.transitId,
  }

  // @ts-ignore
  const webContext: WebContext = window?._Tagular?.webContext
  if (webContext) {
    return {
      ...returnHeaders,
      'webcontext-authenticate-user-id': webContext?.authenticatedUserId,
      'webcontext-from-manual': webContext?.fromManual,
      'webcontext-library-name': webContext?.library?.name,
      'webcontext-library-version': webContext?.library?.version,
      'webcontext-manual-instance-id': webContext?.manualInstanceId,
      'webcontext-page-referrer': webContext?.page?.referrer,
      'webcontext-page-title': webContext?.page?.title,
      'webcontext-page-url': webContext?.page?.url,
      'webcontext-page-revision-id': webContext?.page?.revisionId,
      'webcontext-page-page-type': webContext?.page?.pageType,
      'webcontext-page-post-id': webContext?.page?.postId,
      'webcontext-page-timezone-offset': webContext?.timezoneOffset,
      'webcontext-cross-site-id': webContext?.crossSiteId,
      'webcontext-hash-id': webContext?.hashId,
      'webcontext-cohesion-consent': webContext?.cohesionConsent,
      'webcontext-cookie-consent-essential':
        webContext?.cookieConsent.essential,
      'webcontext-cookie-consent-performance':
        webContext?.cookieConsent.performance,
      'webcontext-cookie-consent-preference':
        webContext?.cookieConsent.preference,
      'webcontext-cookie-consent-targeting': webContext?.cookieConsent.social,
      'webcontext-cookie-consent-social': webContext?.cookieConsent.targeting,
    }
  }

  return returnHeaders
}

// Hit our API using GraphQL
export async function graphQL(
  payload: Record<string, any>,
  headers?: Record<string, any>
) {
  return graphQLRequest(environment.apiBaseUrl, payload, headers)
}

// Hit our API through VeryGoodSecurity proxy
export async function graphQLVGS(
  payload: Record<string, any>,
  headers?: Record<string, any>
) {
  return graphQLRequest(environment.vgsBaseUrl, payload, headers)
}

// Hit the Sage API using GraphQL (like from Bankrate Compare)
export async function graphQLSage(
  payload: Record<string, any>,
  headers?: Record<string, any>
) {
  // When working locally, this will hit your current api instance
  // - not the sage api if running a different productName
  return graphQLRequest(environment.sageApiBaseUrl, payload, headers)
}

async function graphQLRequest(
  baseUrl: string,
  payload: Record<string, any>,
  headers: Record<string, any> = {}
) {
  const graphQLAst = gql`
    ${payload.query}
  `
  const queryParam = graphQLAst.definitions
    .map((definition) => definition['name']['value'])
    .join(',')

  // @ts-ignore
  const referrer = window.document.referrer

  return http.post(`${baseUrl}/graphql?queries=${queryParam}`, payload, {
    headers: { ...headers, ...cohesionHeaders(), referrer },
  })
}

const isCheckAuthInUrl = (err) =>
  err?.response?.request?.responseURL
    ?.toLowerCase()
    .includes('check-authentication')

const isUnauthorizedResponse = (err) =>
  err.response?.status === 401 ||
  err.response?.data?.message?.toLowerCase().includes('unauthorized')

httpBorrowerPortal.interceptors.response.use(undefined, (err) => {
  if (!isCheckAuthInUrl(err) && isUnauthorizedResponse(err)) {
    window.location.href =
      environment.productName === 'sage' ||
      environment.productName === 'sage-home-loans'
        ? borrowerPortalLoginUrl
        : window.location.origin
  }
  return Promise.reject(err)
})

axiosRetry(httpBorrowerPortal, { retries: 0 })

export async function borrowerPortalRequest(
  endpoint: string,
  payload: Record<string, any> = {},
  headers: Record<string, any> = {},
  method = 'post'
) {
  const referrer = window.document.referrer

  const csrf = getCSRFToken()

  const axiosConfig = {
    headers: { ...headers, ...cohesionHeaders(), referrer, csrf },
    'axios-retry': {
      retries: 3,
      retryDelay: (retryCount: number) => retryCount * 1000,
    },
  }

  switch (method) {
    case 'patch':
      return httpBorrowerPortal.patch(endpoint, payload, axiosConfig)
    case 'put':
      return httpBorrowerPortal.put(endpoint, payload, axiosConfig)
    case 'post':
      return httpBorrowerPortal.post(endpoint, payload, axiosConfig)
    case 'get':
      return httpBorrowerPortal.get(endpoint, axiosConfig)
    case 'delete':
      return httpBorrowerPortal.delete(endpoint, axiosConfig)
    default:
      throw new Error(`Unsupported method: ${method}`)
  }
}
