import { LoanPurpose } from './generated/graphql-types'

export enum QuestionSetSlug {
  AmazonBenefits = 'amazon-benefits',
  PrePricingPurchase = 'pre-pricing-purchase',
  ApplyPurchase = 'apply-purchase',
  PrePricingRefinance = 'pre-pricing-refinance',
  ApplyRefinance = 'apply-refinance',
  Compare = 'compare-ntl',
  NTL = 'ntl',
  PhoneApp = 'phone-app',
  FullPurchase = 'full-purchase',
  FullRefinance = 'full-refinance',
  PurchaseFastPass = 'purchase-fastpass',
}

export enum SplitFlowSegments {
  PrePricing = 'prePricing',
  Apply = 'apply',
}

type SplitMapType = Record<
  LoanPurpose,
  Record<SplitFlowSegments, QuestionSetSlug>
>

export const splitFlowSlugMap: SplitMapType = {
  [LoanPurpose.Refinance]: {
    [SplitFlowSegments.PrePricing]: QuestionSetSlug.PrePricingRefinance,
    [SplitFlowSegments.Apply]: QuestionSetSlug.ApplyRefinance,
  },
  [LoanPurpose.Purchase]: {
    [SplitFlowSegments.PrePricing]: QuestionSetSlug.PrePricingPurchase,
    [SplitFlowSegments.Apply]: QuestionSetSlug.ApplyPurchase,
  },
}
