import { environment } from '@mortgage-pos/ui/env'
import customizationsConfig from '@mortgage-pos/ui/data/customizations'

const isAmazonDemo = window.location.search.includes(
  'question-set=amazon-benefits'
)

const config = isAmazonDemo
  ? customizationsConfig['amazon']
  : customizationsConfig[environment.productName] ||
    customizationsConfig['sage']

export const iconMap = (iconName) => {
  return config.icons[iconName]
}

export const loaderMap = (loaderType = 'default') => {
  return config.loaders[loaderType]
    ? config.loaders[loaderType]
    : config.loaders.default
}

export const logoMap = (colorName?) => {
  if (colorName) {
    return config.logos.color[colorName]
  }

  return config.logos.default
}

export const customTextMap = (textType) => {
  return config.customText[textType]
}
