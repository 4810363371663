import rollbar from './rollbar'
import incense from '@mortgage-pos/ui/incense'
import newRelic from '@mortgage-pos/ui/services/newRelic'
import { gql } from 'apollo-boost'
import { ResponseObject } from 'got'
import { CustomError } from '@mortgage-pos/error-handling'
import { environment } from '@mortgage-pos/ui/env'
import { GraphQLError, GraphQLFormattedError } from 'graphql'
import { borrowerPortalRequest, graphQL, graphQLVGS } from './http'

import {
  redactAll,
  getGraphQLString,
  extractGQLErrors,
  convertYesNoToTrueFalse,
} from '@mortgage-pos/utils'

import {
  IncomeSource,
  FillReoParams,
  LiabilityAnswers,
  QuestionnaireType,
  CoBorrowerAddress,
  DemographicsAnswers,
  CreditReportType,
  Auth0AccountCreationResponse,
  MortgageEstimate,
  DisclosureInformation,
  VerifyWithLastFourPhoneResult,
} from '@mortgage-pos/types'

type ErrorContext = {
  errors: any[]
  bankrateLeadId: string
  response: ResponseObject
}

interface GraphQLErrorWithContext extends GraphQLFormattedError {
  metadata?: ErrorContext
}

export default class ApplicationClient {
  static async initialize(
    bankrateLeadId: string,
    loanOfficerId: number,
    fBLeadId: string,
    leadSource: string,
    landingUrl: string,
    sourceLeadId: string,
    referrerUrl: string
  ): Promise<{
    applicationId: number
    applicationGuid: string
    applicationLeadSource: string
  }> {
    try {
      const {
        data: { data, errors },
      } = await graphQL({
        query: applicationInitializeMutation,
        variables: {
          bankrateLeadId,
          loanOfficerId,
          fBLeadId,
          questionnaireType: QuestionnaireType.FULL1003,
          leadSource: leadSource ?? '',
          landingUrl,
          sourceLeadId,
          referrerUrl,
        },
      })

      const applicationId = data?.initialize?.applicationId
      const applicationGuid = data?.initialize?.applicationGuid
      const applicationLeadSource = data?.initialize?.applicationLeadSource

      if ((errors && errors.length) || !applicationId || !applicationGuid) {
        const error: GraphQLErrorWithContext = new GraphQLError(
          'Failed to initialize application for user.'
        )

        error.metadata = { errors, bankrateLeadId } as ErrorContext
        throw error
      }

      newRelic.increment('initialize_application.success', [
        { key: 'applicationId', value: String(applicationId) },
      ])
      return {
        applicationId,
        applicationGuid,
        applicationLeadSource,
      }
    } catch (e) {
      newRelic.increment('initialize_application.error', [
        { key: 'bankrateLeadId', value: bankrateLeadId },
        { key: 'fBLeadId', value: fBLeadId },
      ])

      const error = new GraphQLError(
        'Failed to initialize questionnaire for user.'
      )

      incense(e)
        .details({
          name: 'InitializeApplicationError',
          message: 'Failed to initialize questionnaire for user.',
        })
        .sensitive({
          bankrateLeadId,
          loanOfficerId,
          fBLeadId,
          leadSource,
          landingUrl,
          sourceLeadId,
          referrerUrl,
        })
        .error()

      throw error
    }
  }

  static async updateApplicationSession(
    applicationGuid: string
  ): Promise<boolean> {
    try {
      const {
        data: { errors },
      } = await graphQL({
        query: applicationUpdateSessionMutation,
        variables: { applicationGuid },
      })

      if (errors && errors.length) {
        newRelic.increment('update_application_session.error')

        const error: GraphQLErrorWithContext = new GraphQLError(
          'Failed to update application session.'
        )

        rollbar.error(error, {
          applicationGuid,
          sensitive: { errors },
        })

        return false
      }

      newRelic.increment('update_application_session.success')
      return true
    } catch (error) {
      const errorMetadata = {
        applicationGuid,
        sensitive: {
          error,
          gqlErrors: extractGQLErrors(error),
        },
      }

      rollbar.error(
        new CustomError(
          'UpdateApplicationSessionError',
          'Failed to update application session'
        ),
        errorMetadata
      )

      newRelic.increment('update_application_session.error')
      return false
    }
  }

  static async fill(applicationId: number, answers: any) {
    try {
      const {
        data: { errors },
      } = await graphQL({
        query: applicationFillMutation,
        variables: {
          ...convertYesNoToTrueFalse(answers),
        },
      })

      if (errors && errors.length) {
        newRelic.increment('fill_answers.error')

        const error: GraphQLErrorWithContext = new GraphQLError(
          'Failed to fill application answers.'
        )

        const isInIframe = window.location !== window.parent?.location

        rollbar.error(error, {
          applicationId,
          sensitive: { errors },
          isInIframe,
          iframeParentUrl: isInIframe ? window.parent?.location : '',
        })
        return
      }

      newRelic.increment('fill_answers.success')
    } catch (error) {
      const errorMetadata = {
        applicationId,
        sensitive: {
          error,
          gqlErrors: extractGQLErrors(error),
        },
      }

      rollbar.error(
        new CustomError('FillError', 'FillError - failed to fill'),
        errorMetadata
      )

      newRelic.increment('fill_answers.error')
    }
  }

  static async fillSession(questionSet = '') {
    try {
      const {
        data: { errors },
      } = await graphQL({
        query: applicationFillSessionMutation,
        variables: {
          questionSet,
        },
      })

      if (errors && errors.length) {
        newRelic.increment('fill_session.error')

        incense(errors)
          .details({
            name: 'FillSessionMutation',
            message: 'Failed to fill application session',
          })
          .safe({ questionSet })
          .error()

        return
      }

      newRelic.increment('fill_session.success')
    } catch (error) {
      incense(error)
        .details({
          name: 'ApplicationClientFillSession',
          message: 'Error during ApplicationClient.fillSession',
        })
        .safe({
          questionSet,
        })
        .error()

      newRelic.increment('fill_session.error')
    }
  }

  static async fillReo({
    applicationId,
    realEstateOwned,
    realEstateOwnedIds,
  }: FillReoParams) {
    try {
      const {
        data: { data, errors },
      } = await graphQL({
        query: reoFillMutation,
        variables: { applicationId: null, realEstateOwned, realEstateOwnedIds },
      })

      if (errors && errors.length) {
        const error: GraphQLErrorWithContext = new GraphQLError(
          'Failed to fill REO answers'
        )

        rollbar.error(error, { applicationId, sensitive: { errors } })
        newRelic.increment('fill_reo.error')
        return null
      }

      newRelic.increment('fill_reo.success')

      return data.fillReo
    } catch (error) {
      rollbar.error(
        new CustomError('FillReoError', 'FillReoError - failed to fill'),
        {
          applicationId,
          sensitive: {
            error,
            response: error.response ? error.response.body : null,
            gqlVars: { realEstateOwned, realEstateOwnedIds },
          },
        }
      )

      newRelic.increment('fill_reo.error')
      return null
    }
  }

  static async fillCoBorrower(
    applicationId: number,
    coBorrowerId: number,
    answers: any
  ): Promise<number> {
    try {
      const {
        data: { data, errors },
      } = await graphQL({
        query: coBorrowerApplicationFillMutation,
        variables: {
          coBorrowerId,
          applicationId: null,
          answers: convertYesNoToTrueFalse(answers),
        },
      })

      if (errors && errors.length) {
        const error: GraphQLErrorWithContext = new GraphQLError(
          'Failed to fill coborrower answers.'
        )

        rollbar.error(error, { applicationId, sensitive: { errors } })
        newRelic.increment('fill_co_borrower.error')
        return null
      }

      newRelic.increment('fill_co_borrower.success')
      return data.fillCoBorrower.coBorrowerId
    } catch (error) {
      rollbar.error(
        new CustomError(
          'FillCoBorrowerError',
          'FillCoBorrowerError - failed to fill'
        ),
        {
          applicationId,
          coBorrowerId,
          sensitive: {
            error,
            response: error.response ? error.response.body : null,
            gqlVars: { unconvertedAnswers: answers },
          },
        }
      )

      newRelic.increment('fill_co_borrower.error')
      return null
    }
  }

  static async fillCoBorrowerIncome(
    applicationId: number,
    coBorrowerId: number,
    coBorrowerIncomeSources: IncomeSource[]
  ): Promise<{ id: number }[]> {
    try {
      const {
        data: { data, errors },
      } = await graphQL({
        query: coBorrowerApplicationFillIncomeMutation,
        variables: {
          coBorrowerId,
          incomeSources: coBorrowerIncomeSources.map(convertYesNoToTrueFalse),
        },
      })

      if (errors && errors.length) {
        const error: GraphQLErrorWithContext = new GraphQLError(
          'Failed to fill coborrower income answers.'
        )

        rollbar.error(error, {
          applicationId,
          coBorrowerId,
          sensitive: { errors },
        })

        newRelic.increment('fill_co_borrower_income.error')
        return null
      }

      newRelic.increment('fill_co_borrower_income.success')
      return data.fillCoBorrowerIncome
    } catch (error) {
      rollbar.error(
        new CustomError(
          'FillCoBorrowerIncomeError',
          'FillCoBorrowerIncomeError - failed to fill'
        ),
        {
          applicationId,
          coBorrowerId,
          sensitive: {
            error,
            response: error.response ? error.response.body : null,
            gqlVars: { unconvertedIncomeSources: coBorrowerIncomeSources },
          },
        }
      )

      newRelic.increment('fill_co_borrower_income.error')
      return null
    }
  }

  static async fillCoBorrowerAddresses(
    applicationId: number,
    coBorrowerId: number,
    coBorrowerAddresses: CoBorrowerAddress[]
  ): Promise<{ id: number }[]> {
    try {
      const {
        data: { data, errors },
      } = await graphQL({
        query: coBorrowerFillAddressMutation,
        variables: {
          coBorrowerId,
          coBorrowerAddresses: coBorrowerAddresses.map(convertYesNoToTrueFalse),
        },
      })

      if (errors && errors.length) {
        const error: GraphQLErrorWithContext = new GraphQLError(
          'Failed to fill coborrower address answers.'
        )

        rollbar.error(error, {
          applicationId,
          coBorrowerId,
          sensitive: { errors },
        })

        newRelic.increment('fill_co_borrower_address.error')
        return null
      }

      newRelic.increment('fill_co_borrower_address.success')
      return data.fillCoBorrowerAddress
    } catch (error) {
      rollbar.error(
        new CustomError(
          'FillCoBorrowerAddressesError',
          'FillCoBorrowerAddressesError - failed to fill'
        ),
        {
          applicationId,
          coBorrowerId,
          sensitive: {
            error,
            response: error.response ? error.response.body : null,
            gqlVars: { unconvertedAddresses: coBorrowerAddresses },
          },
        }
      )

      newRelic.increment('fill_co_borrower_address.error')
      return null
    }
  }

  static async fillCoBorrowerDemographics(
    applicationId: number,
    coBorrowerId: number,
    coBorrowerDemographics: DemographicsAnswers
  ): Promise<number> {
    try {
      const {
        data: { data, errors },
      } = await graphQL({
        query: applicationFillCoBorrowerDemographicsMutation,
        variables: {
          coBorrowerId,
          demographicsAnswers: coBorrowerDemographics,
        },
      })

      if (errors && errors.length) {
        const error: GraphQLErrorWithContext = new GraphQLError(
          'Failed to fill coborrower demographics answers.'
        )

        rollbar.error(error, {
          applicationId,
          coBorrowerId,
          sensitive: {
            errors,
          },
        })

        newRelic.increment('fill_co_borrower_demographics.error')
        return null
      }

      newRelic.increment('fill_co_borrower_demographics.success')
      return data.fillCoBorrowerDemographics.id
    } catch (error) {
      rollbar.error(
        new CustomError(
          'FillCoBorrowerDemographicsError',
          'FillCoBorrowerDemographicsError - failed to fill'
        ),
        {
          applicationId,
          coBorrowerId,
          sensitive: {
            error,
            response: error.response ? error.response.body : null,
            gqlVars: { coBorrowerDemographics },
          },
        }
      )

      newRelic.increment('fill_co_borrower_demographics.error')
      return null
    }
  }

  static async fillCoBorrowerLiabilities(
    applicationId: number,
    coBorrowerId: number,
    coBorrowerLiabilities: [LiabilityAnswers]
  ): Promise<number> {
    try {
      const {
        data: { data, errors },
      } = await graphQL({
        query: applicationFillCoBorrowerLiabilitiesMutation,
        variables: {
          coBorrowerId,
          liabilities: coBorrowerLiabilities,
        },
      })

      if (errors && errors.length) {
        const error: GraphQLErrorWithContext = new GraphQLError(
          'Failed to fill coborrower liability answers.'
        )

        rollbar.error(error, {
          applicationId,
          coBorrowerId,
          sensitive: { errors, coBorrowerLiabilities },
        })

        newRelic.increment('fill_co_borrower_liabilities.error')
        return null
      }

      newRelic.increment('fill_co_borrower_liabilities.success')
      return data.fillCoBorrowerDemographics.id
    } catch (error) {
      rollbar.error(
        new CustomError(
          'FillCoBorrowerLiabilitiesError',
          'FillCoBorrowerLiabilitiesError - failed to fill'
        ),
        {
          applicationId,
          sensitive: {
            error,
            response: error.response ? error.response.body : null,
            gqlVars: { coBorrowerLiabilities },
          },
        }
      )

      newRelic.increment('fill_co_borrower_liabilities.error')
      return null
    }
  }

  static async fillIncome(
    applicationId: number,
    incomeSources: IncomeSource[]
  ): Promise<{ id: number }[]> {
    try {
      const {
        data: { data, errors },
      } = await graphQL({
        query: applicationFillIncomeMutation,
        variables: {
          applicationId: null,
          incomeSources: incomeSources.map(convertYesNoToTrueFalse),
        },
      })

      if (errors && errors.length) {
        const error: GraphQLErrorWithContext = new GraphQLError(
          'FillIncomeError - GQL failure'
        )

        rollbar.error(error, { applicationId, sensitive: { errors } })

        newRelic.increment('fill_income.error')
        return null
      }

      newRelic.increment('fill_income.success')
      return data.fillIncome
    } catch (error) {
      rollbar.error(
        new CustomError('FillIncomeError', 'FillIncomeError - failed to fill'),
        {
          applicationId,
          sensitive: {
            error,
            gqlErrors: extractGQLErrors(error),
            gqlVars: { unconvertedIncomeSources: incomeSources },
          },
        }
      )

      newRelic.increment('fill_income.error')
      return null
    }
  }

  static async fillAddress(
    applicationId: number,
    { addresses }
  ): Promise<{ id: number }[]> {
    try {
      const {
        data: { data, errors },
      } = await graphQL({
        query: applicationFillAddressMutation,
        variables: {
          addresses: addresses.map(convertYesNoToTrueFalse),
          applicationId: null,
        },
      })

      if (errors && errors.length) {
        const error: GraphQLErrorWithContext = new GraphQLError(
          'Failed to fill address answers.'
        )

        rollbar.error(error, { applicationId, sensitive: { errors } })

        newRelic.increment('fill_address.error')
        return null
      }

      newRelic.increment('fill_address.success')
      return data.fillAddress
    } catch (error) {
      rollbar.error(
        new CustomError(
          'FillAddressError',
          'FillAddressError - failed to fill'
        ),
        {
          applicationId,
          sensitive: {
            error,
            response: error.response ? error.response.body : null,
            gqlVars: { unconvertedAddresses: addresses },
          },
        }
      )

      newRelic.increment('fill_address.error')
      return null
    }
  }

  static async fillDemographics(
    applicationId: number,
    demographics: DemographicsAnswers
  ): Promise<number> {
    try {
      const {
        data: { data, errors },
      } = await graphQL({
        query: applicationFillDemographicsMutation,
        variables: {
          applicationId: null,
          demographicsAnswers: demographics,
        },
      })

      if (errors && errors.length) {
        const error: GraphQLErrorWithContext = new GraphQLError(
          'Failed to fill demographics answers.'
        )

        rollbar.error(error, { applicationId, sensitive: { errors } })

        newRelic.increment('fill_demographics.error')
        return null
      }

      newRelic.increment('fill_demographics.success')

      return data.fillDemographics.id
    } catch (error) {
      rollbar.error(
        new CustomError(
          'FillDemographicsError',
          'FillDemographicsError - failed to fill'
        ),
        {
          applicationId,
          sensitive: {
            error,
            response: error.response ? error.response.body : null,
            gqlVars: { demographics },
          },
        }
      )

      newRelic.increment('fill_demographics.error')
      return null
    }
  }

  static async fillLiabilities(
    applicationId: number,
    liabilities: [LiabilityAnswers]
  ): Promise<number> {
    try {
      const {
        data: { data, errors },
      } = await graphQL({
        query: applicationFillLiabilitiesMutation,
        variables: {
          applicationId: null,
          liabilities,
        },
      })

      if (errors && errors.length) {
        const error: GraphQLErrorWithContext = new GraphQLError(
          'Failed to fill liabilities answers.'
        )

        rollbar.error(error, { applicationId, sensitive: { errors } })

        newRelic.increment('fill_liabilities.error')
        return null
      }

      newRelic.increment('fill_liabilities.success')

      return data.fillLiabilities.id
    } catch (error) {
      rollbar.error(
        new CustomError(
          'FillLiabilitiesError',
          'FillLiabilitiesError - failed to fill'
        ),
        {
          applicationId,
          sensitive: {
            error,
            response: error.response ? error.response.body : null,
            gqlVars: { liabilities },
          },
        }
      )

      newRelic.increment('fill_liabilities.error')
      return null
    }
  }

  static async saveSSNAlias(applicationId: number, ssn: string) {
    try {
      const {
        data: { errors },
      } = await graphQLVGS({
        query: applicationSaveSSNAliasMutation,
        variables: {
          ssnAlias: ssn,
        },
      })

      if (errors && errors.length) {
        const error: GraphQLErrorWithContext = new GraphQLError(
          'SaveSSNAlias - GQL failure'
        )

        rollbar.error(error, { applicationId, sensitive: { errors, ssn } })

        newRelic.increment('save_ssn_alias.error')
        return
      }

      newRelic.increment('save_ssn_alias.success')
    } catch (error) {
      rollbar.error(
        new CustomError('SaveSSNAlias', 'SaveSSNAlias - failed to fill'),
        {
          applicationId,
          sensitive: {
            ssn,
            error,
            response: error.response ? error.response.body : error,
          },
        }
      )

      newRelic.increment('save_ssn_alias.error')
    }
  }

  static async saveCoBorrowerSSNAlias(
    applicationId: number,
    coBorrowerId: number,
    ssn: string
  ) {
    try {
      const {
        data: { errors },
      } = await graphQLVGS({
        query: applicationSaveCoBorrowerSSNAliasMutation,
        variables: {
          coBorrowerId,
          ssnAlias: ssn,
        },
      })

      if (errors && errors.length) {
        const error: GraphQLErrorWithContext = new GraphQLError(
          'SaveCoBorrowerSSNAlias - GQL failure'
        )

        rollbar.error(error, {
          applicationId,
          coBorrowerId,
          sensitive: { errors, ssn },
        })

        newRelic.increment('save_co_borrower_ssn_alias.error')
        return
      }

      newRelic.increment('save_co_borrower_ssn_alias.success')
    } catch (error) {
      rollbar.error(
        new CustomError(
          'SaveCoBorrowerSSNAlias',
          'SaveCoBorrowerSSNAlias - failed to fill'
        ),
        {
          applicationId,
          coBorrowerId,
          sensitive: {
            ssn,
            error,
            response: error.response ? error.response.body : error,
          },
        }
      )

      newRelic.increment('save_co_borrower_ssn_alias.error')
    }
  }

  static async softPullCreditScore(
    applicationId: number,
    returnMatchingEstimate = false
  ) {
    try {
      const {
        data: { errors, data },
      } = await graphQL({
        query: applicationSoftPullCreditScoreMutation,
        variables: {
          applicationId: null,
          returnMatchingEstimate,
        },
      })

      if (errors && errors.length) {
        const error: GraphQLErrorWithContext = new GraphQLError(
          'Failed to soft pull credit score.'
        )

        rollbar.error(error, { applicationId, sensitive: { errors } })

        newRelic.increment('soft_pull_credit.error')
        return { success: false }
      }

      const mortgageRateEstimate: MortgageEstimate =
        data?.softPullCreditScore?.mortgageRateEstimate?.[0] ?? null

      newRelic.increment('soft_pull_credit.success')
      return {
        success: data?.softPullCreditScore?.success,
        mortgageRateEstimate,
      }
    } catch (e) {
      const response = redactAll(e.response ? e.response.body : e)

      rollbar.error('Failed to soft pull credit score', e, {
        applicationId,
        response,
      })

      newRelic.increment('soft_pull_credit.error')
    }
  }

  static async pullCredit(applicationId: number, type: CreditReportType) {
    const newRelicTags = [{ key: 'type', value: type }]

    try {
      await borrowerPortalRequest(`/pull-report${type ? '?type=' + type : ''}`)
      newRelic.increment('pull_credit.success', newRelicTags)
    } catch (error) {
      newRelic.increment('pull_credit.error', newRelicTags)
    }
  }

  static async compareCreditScores(applicationId: number) {
    try {
      const {
        data: { data, errors },
      } = await graphQL({
        query: compareCreditScoresQuery,
      })

      if (errors && errors.length) {
        const error: GraphQLErrorWithContext = new GraphQLError(
          'Failed to compare credit scores'
        )

        rollbar.error(error, { applicationId, sensitive: { errors } })

        newRelic.increment('compare_credit_scores.error')
        return null
      }

      newRelic.increment('compare_credit_scores.success')

      return data.compareCreditScores.isEqual
    } catch (e) {
      const response = redactAll(e.response ? e.response.body : e)

      rollbar.error('Failed to compare credit scores', e, {
        applicationId,
        response,
      })

      newRelic.increment('compare_credit_scores.error')
      return null
    }
  }

  static async updateQStatus(applicationId: number, stringStatus: string) {
    const newRelicTags = [{ key: 'status', value: stringStatus }]

    try {
      const {
        data: { errors },
      } = await graphQL({
        query: updateQStatusQuery,
        variables: { applicationId: null, status: stringStatus },
      })

      if (errors && errors.length) {
        const error: GraphQLErrorWithContext = new GraphQLError(
          'Failed to update Q status'
        )

        rollbar.error(error, {
          applicationId,
          status: stringStatus,
          sensitive: { errors },
        })

        newRelic.increment('update_application_status.error', newRelicTags)
        return
      }

      newRelic.increment('update_application_status.success', newRelicTags)
    } catch (e) {
      newRelic.increment('update_application_status.error', newRelicTags)
    }
  }

  static async createAuth0Account(
    email: string,
    password: string,
    firstName: string
  ): Promise<{
    data: { createAuth0Account: Auth0AccountCreationResponse }
    errors?: any
  }> {
    let authResponse
    try {
      newRelic.increment('create_auth0_account.attempt')
      authResponse = await graphQL({
        query: createAuth0AccountMutation,
        variables: {
          email,
          password,
          firstName,
        },
      })
    } catch (err) {
      const errorMetadata = {
        sensitive: {
          err,
        },
      }
      const error = new CustomError(
        'CreateAccountError',
        'CreateAccountError - Create Auth0 Account failed'
      )
      rollbar.error(error, errorMetadata)

      newRelic.increment('create_auth0_account.failed')
      return
    }

    newRelic.increment('create_auth0_account.success')

    const accessToken =
      authResponse?.data?.data?.createAuth0Account?.token?.access_token

    if (accessToken) {
      try {
        await borrowerPortalRequest(
          '/login',
          {},
          { Authorization: `Bearer ${accessToken}` }
        )
      } catch (err) {
        const errorMetadata = {
          sensitive: {
            err,
          },
        }
        const error = new CustomError(
          'SessionError',
          'SessionError - Failed to set Session Cookie'
        )

        rollbar.error(error, errorMetadata)
      }
    }

    return authResponse?.data
  }

  static async getLicensedStates() {
    let response
    try {
      newRelic.increment('get_licensed_states.attempt')

      response = graphQL({
        query: getLicensedStatesQuery,
        variables: {
          productName: environment.productName ?? 'sage',
        },
      })
    } catch (e) {
      const response = redactAll(e.response ? e.response.body : e)
      rollbar.error('Get Licensed States Failed', e, { response })
      newRelic.increment('get_licensed_states.failed')
      return
    }

    newRelic.increment('get_licensed_states.success')
    return response
  }

  static async fillDisclosures(disclosures: DisclosureInformation[]) {
    try {
      const {
        data: { errors },
      } = await graphQL({
        query: saveApplicationDisclosures,
        variables: {
          disclosures,
        },
      })

      if (errors && errors.length) {
        newRelic.increment('fill_disclosures.error')

        return
      }

      newRelic.increment('fill_disclosures.success')
      return
    } catch (error) {
      incense(error)
        .details({
          name: 'ApplicationClientFillDisclosures',
          message: 'Error during ApplicationClient.fillDisclosures',
        })
        .safe({
          disclosures,
        })
        .error()

      newRelic.increment('fill_disclosures.error')
    }
    return
  }

  static async fillCoBorrowerDisclosures(disclosures: DisclosureInformation[]) {
    try {
      const {
        data: { errors },
      } = await graphQL({
        query: saveCoBorrowerApplicationDisclosures,
        variables: {
          disclosures,
        },
      })

      if (errors && errors.length) {
        newRelic.increment('fill_co_borrower_disclosures.error')

        return
      }

      newRelic.increment('fill_co_borrower_disclosures.success')
      return
    } catch (error) {
      incense(error)
        .details({
          name: 'ApplicationClientFillCoBorrowerDisclosures',
          message: 'Error during ApplicationClient.fillCoBorrowerDisclosures',
        })
        .safe({
          disclosures,
        })
        .error()

      newRelic.increment('fill_co_borrower_disclosures.error')
    }
    return
  }

  static async verifyUserWithLastFourPhone(
    lastFourPhone: string,
    applicationGuid: string,
    zipCodeVerify: string
  ): Promise<VerifyWithLastFourPhoneResult> {
    try {
      const {
        data: { data, errors },
      } = await graphQL({
        query: verifyUserWithLastFourPhoneQuery,
        variables: {
          lastFourPhone,
          applicationGuid,
          zipCodeVerify,
        },
      })
      if (errors && errors.length) {
        newRelic.increment('verify_with_phone.error')

        return
      }

      newRelic.increment('verify_with_phone.success')

      return data?.verifyWithLastFourPhone
    } catch (error) {
      incense(error)
        .details({
          name: 'ApplicationClientVerifyUserWithLastFourPhone',
          message: 'Error during ApplicationClient.verifyUserWithLastFourPhone',
        })
        .sensitive({
          lastFourPhone,
          zipCodeVerify,
        })
        .error()

      return
    }
  }
}

export const applicationInitializeMutation = getGraphQLString(gql`
  mutation initialize(
    $bankrateLeadId: String
    $loanOfficerId: Int
    $fBLeadId: String
    $questionnaireType: String
    $leadSource: String
    $landingUrl: String
    $referrerUrl: String
    $sourceLeadId: String
  ) {
    initialize(
      bankrateLeadId: $bankrateLeadId
      loanOfficerId: $loanOfficerId
      fBLeadId: $fBLeadId
      questionnaireType: $questionnaireType
      leadSource: $leadSource
      landingUrl: $landingUrl
      referrerUrl: $referrerUrl
      sourceLeadId: $sourceLeadId
    ) {
      applicationId
      applicationGuid
      applicationLeadSource
    }
  }
`)

export const applicationUpdateSessionMutation = getGraphQLString(gql`
  mutation updateApplicationSession($applicationGuid: String!) {
    updateApplicationSession(applicationGuid: $applicationGuid)
  }
`)

export const applicationFillMutation = getGraphQLString(gql`
  mutation fill(
    $firstName: String
    $lastName: String
    $email: String
    $phone: String
    $street: String
    $apt: String
    $city: String
    $state: String
    $zipCode: String
    $propertyUse: String
    $propertyType: String
    $estimatedValue: Float
    $remainingBalance: Float
    $cashOut: Float
    $cashOutOptions: String
    $yearBuilt: Int
    $yearPurchased: Int
    $propertyAttachment: String
    $propertyNumberOfStories: String
    $isCurrentAddress: Boolean
    $hasResidedTwoYears: Boolean
    $isTakingCashOut: Boolean
    $hasCoBorrower: Boolean
    $maritalStatus: String
    $termsOfUsePrivacyPolicyAndElectronicConsentAgreement: Boolean
    $affiliatedBusinessArrangementDisclosure: Boolean
    $personalInfoDisclosure: Boolean
    $abadDisclosure: Boolean
    $tcpaDisclosure: Boolean
    $moreThanOneCoBorrower: Boolean
    $expenseRent: Float
    $expenseFirstMortgage: Float
    $expenseSecondMortgage: Float
    $expenseHomeownerInsurance: Float
    $expensePropertyTax: Float
    $expenseMortgageInsurance: Float
    $expenseHOAdues: Float
    $declarationOutstandingJudgements: Boolean
    $declarationBankrupt: Boolean
    $declarationForeclosed: Boolean
    $declarationLawsuit: Boolean
    $declarationForeclosedDirect: Boolean
    $declarationFederalDebt: Boolean
    $declarationAlimony: Boolean
    $declarationDownPaymentBorrowed: Boolean
    $declarationDownPaymentBorrowedAmount: Int
    $declarationEndorserNote: Boolean
    $declarationCitizen: Boolean
    $declarationResident: Boolean
    $declarationPrimaryResidence: Boolean
    $declarationOwnership: Boolean
    $declarationMilitary: Boolean
    $declarationVALoan: Boolean
    $declarationFHALoan: Boolean
    $declarationDependents: Int
    $declarationSchoolingYears: String
    $declarationPropertyType: String
    $declarationPropertyTitle: String
    $undisclosedBorrowedFundsIndicator: Boolean
    $undisclosedBorrowedFundsAmount: Float
    $declarationNewCredit: Boolean
    $declarationSubjectToLien: Boolean
    $declarationConveyedTitle: Boolean
    $declarationBankruptcyType: BankruptcyTypes
    $declarationRelationWithSeller: Boolean
    $typeProperty: String
    $schooling: String
    $gender: [String]
    $ethnicity: [String]
    $race: [String]
    $dateOfBirth: String
    $creditScore: Int
    $originalCost: Int
    $homeBusiness: Boolean
    $propertyCondoDesignType: String
    $declarationCitizenStatus: String
    $countryOfCitizenship: String
    $nonSpousePropertyRights: Boolean
    $nonSpousePropertyRightsState: String
    $nonSpousePropertyRightsType: String
    $addDependentAges: [DependentAge]
    $desiredRateType: String
    $propertyPurchasePrice: Float
    $propertyDownPayment: Float
    $purchaseStage: String
    $purchaseKnownAddress: Boolean
    $loanPurpose: String
    $refiReason: String
    $timeInHome: String
    $escrow: Boolean
    $escrowRollIn: Boolean
    $rollInFees: Boolean
    $forbearance: Boolean
    $militaryDetails: [String]
    $militaryServiceMember: String
    $militaryServiceTourDate: String
    $otherAnnualIncomeAmount: Float
    $autoCompleteAddressFields: String
    $submissionAuthorization: Boolean
    $termsAndCreditDisclosure: Boolean
    $firstTimeHomeBuyer: Boolean
    $grossAnnualIncome: Float
    $monthlyDebtPayments: Float
    $totalAssets: Float
    $closingDate: String
    $suffix: String
    $agentFirstName: String
    $agentLastName: String
    $agentEmail: String
    $agentPhone: String
    $agentCompany: String
  ) {
    fill(
      answers: {
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
        street: $street
        apt: $apt
        city: $city
        state: $state
        zipCode: $zipCode
        propertyUse: $propertyUse
        propertyType: $propertyType
        estimatedValue: $estimatedValue
        remainingBalance: $remainingBalance
        cashOut: $cashOut
        cashOutOptions: $cashOutOptions
        yearBuilt: $yearBuilt
        yearPurchased: $yearPurchased
        propertyAttachment: $propertyAttachment
        propertyNumberOfStories: $propertyNumberOfStories
        isCurrentAddress: $isCurrentAddress
        hasResidedTwoYears: $hasResidedTwoYears
        isTakingCashOut: $isTakingCashOut
        hasCoBorrower: $hasCoBorrower
        maritalStatus: $maritalStatus
        termsOfUsePrivacyPolicyAndElectronicConsentAgreement: $termsOfUsePrivacyPolicyAndElectronicConsentAgreement
        affiliatedBusinessArrangementDisclosure: $affiliatedBusinessArrangementDisclosure
        tcpaDisclosure: $tcpaDisclosure
        personalInfoDisclosure: $personalInfoDisclosure
        abadDisclosure: $abadDisclosure
        moreThanOneCoBorrower: $moreThanOneCoBorrower
        expenseRent: $expenseRent
        expenseFirstMortgage: $expenseFirstMortgage
        expenseSecondMortgage: $expenseSecondMortgage
        expenseHomeownerInsurance: $expenseHomeownerInsurance
        expensePropertyTax: $expensePropertyTax
        expenseMortgageInsurance: $expenseMortgageInsurance
        expenseHOAdues: $expenseHOAdues
        declarationOutstandingJudgements: $declarationOutstandingJudgements
        declarationBankrupt: $declarationBankrupt
        declarationForeclosed: $declarationForeclosed
        declarationLawsuit: $declarationLawsuit
        declarationForeclosedDirect: $declarationForeclosedDirect
        declarationFederalDebt: $declarationFederalDebt
        declarationAlimony: $declarationAlimony
        declarationDownPaymentBorrowed: $declarationDownPaymentBorrowed
        declarationDownPaymentBorrowedAmount: $declarationDownPaymentBorrowedAmount
        declarationEndorserNote: $declarationEndorserNote
        declarationCitizen: $declarationCitizen
        declarationResident: $declarationResident
        declarationPrimaryResidence: $declarationPrimaryResidence
        declarationOwnership: $declarationOwnership
        declarationMilitary: $declarationMilitary
        declarationVALoan: $declarationVALoan
        declarationFHALoan: $declarationFHALoan
        declarationDependents: $declarationDependents
        declarationSchoolingYears: $declarationSchoolingYears
        declarationPropertyType: $declarationPropertyType
        declarationPropertyTitle: $declarationPropertyTitle
        declarationRelationWithSeller: $declarationRelationWithSeller
        undisclosedBorrowedFundsIndicator: $undisclosedBorrowedFundsIndicator
        undisclosedBorrowedFundsAmount: $undisclosedBorrowedFundsAmount
        declarationNewCredit: $declarationNewCredit
        declarationSubjectToLien: $declarationSubjectToLien
        declarationConveyedTitle: $declarationConveyedTitle
        declarationBankruptcyType: $declarationBankruptcyType
        typeProperty: $typeProperty
        schooling: $schooling
        gender: $gender
        ethnicity: $ethnicity
        race: $race
        dateOfBirth: $dateOfBirth
        creditScore: $creditScore
        originalCost: $originalCost
        homeBusiness: $homeBusiness
        propertyCondoDesignType: $propertyCondoDesignType
        declarationCitizenStatus: $declarationCitizenStatus
        countryOfCitizenship: $countryOfCitizenship
        nonSpousePropertyRights: $nonSpousePropertyRights
        nonSpousePropertyRightsState: $nonSpousePropertyRightsState
        nonSpousePropertyRightsType: $nonSpousePropertyRightsType
        addDependentAges: $addDependentAges
        desiredRateType: $desiredRateType
        propertyPurchasePrice: $propertyPurchasePrice
        propertyDownPayment: $propertyDownPayment
        purchaseStage: $purchaseStage
        purchaseKnownAddress: $purchaseKnownAddress
        loanPurpose: $loanPurpose
        refiReason: $refiReason
        timeInHome: $timeInHome
        escrow: $escrow
        escrowRollIn: $escrowRollIn
        rollInFees: $rollInFees
        forbearance: $forbearance
        militaryDetails: $militaryDetails
        militaryServiceMember: $militaryServiceMember
        militaryServiceTourDate: $militaryServiceTourDate
        otherAnnualIncomeAmount: $otherAnnualIncomeAmount
        autoCompleteAddressFields: $autoCompleteAddressFields
        submissionAuthorization: $submissionAuthorization
        termsAndCreditDisclosure: $termsAndCreditDisclosure
        firstTimeHomeBuyer: $firstTimeHomeBuyer
        grossAnnualIncome: $grossAnnualIncome
        monthlyDebtPayments: $monthlyDebtPayments
        totalAssets: $totalAssets
        closingDate: $closingDate
        suffix: $suffix
        agentFirstName: $agentFirstName
        agentLastName: $agentLastName
        agentEmail: $agentEmail
        agentPhone: $agentPhone
        agentCompany: $agentCompany
      }
    )
  }
`)

export const applicationFillSessionMutation = getGraphQLString(gql`
  mutation fillSession($questionSet: String!) {
    fillSession(questionSet: $questionSet)
  }
`)

// TODO: remove applicationId from query/mutation
export const reoFillMutation = getGraphQLString(gql`
  mutation fillReo(
    $applicationId: Int
    $realEstateOwned: [RealEstateOwnedAnswer]
    $realEstateOwnedIds: [Int]
  ) {
    fillReo(
      applicationId: $applicationId
      realEstateOwned: $realEstateOwned
      realEstateOwnedIds: $realEstateOwnedIds
    ) {
      realEstateOwnedIds
    }
  }
`)

// TODO: remove applicationId from query/mutation
export const coBorrowerApplicationFillMutation = getGraphQLString(gql`
  mutation fillCoBorrower(
    $coBorrowerId: Int
    $applicationId: Int
    $answers: ApplicationAnswers
  ) {
    fillCoBorrower(
      coBorrowerId: $coBorrowerId
      applicationId: $applicationId
      answers: $answers
    ) {
      coBorrowerId
    }
  }
`)

export const coBorrowerApplicationFillIncomeMutation = getGraphQLString(gql`
  mutation fillCoBorrowerIncome(
    $coBorrowerId: Int!
    $incomeSources: [IncomeSource!]!
  ) {
    fillCoBorrowerIncome(
      coBorrowerId: $coBorrowerId
      incomeSources: $incomeSources
    ) {
      id
    }
  }
`)

export const coBorrowerFillAddressMutation = getGraphQLString(gql`
  mutation fillCoBorrowerAddresses(
    $coBorrowerId: Int!
    $coBorrowerAddresses: [CoBorrowerAddress!]!
  ) {
    fillCoBorrowerAddresses(
      coBorrowerId: $coBorrowerId
      coBorrowerAddresses: $coBorrowerAddresses
    ) {
      id
    }
  }
`)

export const applicationFillCoBorrowerDemographicsMutation =
  getGraphQLString(gql`
    mutation fillCoBorrowerDemographics(
      $coBorrowerId: Int!
      $demographicsAnswers: DemographicsAnswers
    ) {
      fillCoBorrowerDemographics(
        coBorrowerId: $coBorrowerId
        demographicsAnswers: $demographicsAnswers
      ) {
        id
      }
    }
  `)

export const applicationFillCoBorrowerLiabilitiesMutation =
  getGraphQLString(gql`
    mutation fillCoBorrowerLiabilities(
      $coBorrowerId: Int!
      $liabilities: [LiabilityAnswers]
    ) {
      fillCoBorrowerLiabilities(
        coBorrowerId: $coBorrowerId
        liabilities: $liabilities
      ) {
        id
      }
    }
  `)

export const applicationSaveSSNAliasMutation = getGraphQLString(gql`
  mutation saveSSNAlias($ssnAlias: String!) {
    saveSSNAlias(ssnAlias: $ssnAlias)
  }
`)

export const applicationSaveCoBorrowerSSNAliasMutation = getGraphQLString(gql`
  mutation saveCoBorrowerSSNAlias($coBorrowerId: Int!, $ssnAlias: String!) {
    saveCoBorrowerSSNAlias(coBorrowerId: $coBorrowerId, ssnAlias: $ssnAlias)
  }
`)

// TODO: remove applicationId from query/mutation
export const applicationFillIncomeMutation = getGraphQLString(gql`
  mutation fillIncome($applicationId: Int, $incomeSources: [IncomeSource]) {
    fillIncome(applicationId: $applicationId, incomeSources: $incomeSources) {
      id
    }
  }
`)

// TODO: remove applicationId from query/mutation
export const applicationFillAddressMutation = getGraphQLString(gql`
  mutation fillAddress($applicationId: Int, $addresses: [MutationFillAddress]) {
    fillAddress(applicationId: $applicationId, addresses: $addresses) {
      id
    }
  }
`)

// TODO: remove applicationId from query/mutation
export const applicationFillDemographicsMutation = getGraphQLString(gql`
  mutation fillDemographics(
    $applicationId: Int
    $demographicsAnswers: DemographicsAnswers
  ) {
    fillDemographics(
      applicationId: $applicationId
      demographicsAnswers: $demographicsAnswers
    ) {
      id
    }
  }
`)

// TODO: remove applicationId from query/mutation
export const applicationFillLiabilitiesMutation = getGraphQLString(gql`
  mutation fillLiabilities(
    $applicationId: Int
    $liabilities: [LiabilityAnswers]
  ) {
    fillLiabilities(applicationId: $applicationId, liabilities: $liabilities) {
      id
    }
  }
`)

// TODO: remove applicationId from query/mutation
export const applicationSoftPullCreditScoreMutation = getGraphQLString(gql`
  mutation softPullCreditScore(
    $applicationId: Int
    $returnMatchingEstimate: Boolean
  ) {
    softPullCreditScore(
      applicationId: $applicationId
      returnMatchingEstimate: $returnMatchingEstimate
    ) {
      success
      mortgageRateEstimate {
        estimatedInterestRate
        openedDate
        currentBalance
        loanAmount
        paymentScheduleMonthCount
        paymentDueAmount
      }
    }
  }
`)

export const createAuth0AccountMutation = getGraphQLString(gql`
  mutation createAuth0Account(
    $email: String!
    $password: String
    $firstName: String
  ) {
    createAuth0Account(
      email: $email
      password: $password
      firstName: $firstName
    ) {
      successful
      auth0Id
      token {
        access_token
        token_type
        expires_in
      }
    }
  }
`)

export const getLicensedStatesQuery = getGraphQLString(gql`
  query getLicensedStates($productName: String) {
    getLicensedStates(productName: $productName)
  }
`)

// TODO: remove applicationId from query/mutation
export const updateQStatusQuery = getGraphQLString(gql`
  mutation updateQStatus($applicationId: Int, $status: Status!) {
    updateQStatus(applicationId: $applicationId, status: $status)
  }
`)

export const compareCreditScoresQuery = getGraphQLString(gql`
  query compareCreditScores {
    compareCreditScores {
      isEqual
    }
  }
`)

export const saveApplicationDisclosures = getGraphQLString(gql`
  mutation saveApplicationDisclosures($disclosures: [DisclosureInformation]) {
    saveApplicationDisclosures(disclosures: $disclosures)
  }
`)

export const saveCoBorrowerApplicationDisclosures = getGraphQLString(gql`
  mutation saveCoBorrowerApplicationDisclosures(
    $disclosures: [DisclosureInformation]
  ) {
    saveCoBorrowerApplicationDisclosures(disclosures: $disclosures)
  }
`)

export const verifyUserWithLastFourPhoneQuery = getGraphQLString(gql`
  query verifyWithLastFourPhone(
    $lastFourPhone: String!
    $applicationGuid: String!
    $zipCodeVerify: String!
  ) {
    verifyWithLastFourPhone(
      lastFourPhone: $lastFourPhone
      applicationGuid: $applicationGuid
      zipCodeVerify: $zipCodeVerify
    ) {
      phoneVerified
      zipCodeVerified
    }
  }
`)
