import sageConfig from './sage.config'
import amazonDemoConfig from './amazon-demo.config'
import bankrateConfig from './bankrate.config'
import sageHomeLoansConfig from './sage-home-loans.config'

export default {
  sage: sageConfig,
  bankrate: bankrateConfig,
  amazon: amazonDemoConfig,
  'sage-home-loans': sageHomeLoansConfig,
}
