import { z } from 'zod'
import { LoanFileSection, LoanFileSubSection } from './LoanFileReview'

// Update available sections to include co‑borrower groups
export const loanFileReviewSections = [
  'Personal Info',
  'Contact Info',
  'Citizenship',
  'Military Status',
  'Family Details',
  'Addresses',
  'Additional Properties',
  'Loan Details - Property',
  'Loan Details - Loan',
  'Loan Details - AVM',
  'Loan Details - Pricing',
  'Loan Details - NTB',
  'Employment',
  'Assets',
  'Liabilities',
  'Declarations & Demographics',
  'Co-Borrower Personal Info',
  'Co-Borrower Contact Info',
  'Co-Borrower Citizenship',
  'Co-Borrower Military Status',
  'Co-Borrower Family Details',
] as const

export const loanFileReviewSection = z.enum(loanFileReviewSections)
export type LoanFileReviewSection = z.infer<typeof loanFileReviewSection>

// Define field metadata
export interface FieldMetadata {
  label: string
  description: string
  section: LoanFileSection
  subSection?: LoanFileSubSection
  severity: 'error' | 'warning' | 'info'
  requiredCondition?: (data: any) => boolean
  helpText?: string
  path?: string // Path to the field in the application data
}

// Define the field mappings. Note: any duplicate field names in the service are merged into a single entry.
export const loanFileReviewFieldMappings: Record<string, FieldMetadata> = {
  // Personal Info
  'First Name': {
    label: 'First Name',
    description: 'Primary borrower first name',
    section: 'borrower',
    subSection: 'personal-info',
    severity: 'error',
    path: 'first_name',
    helpText: 'First name is required for all applications',
    requiredCondition: () => true, // Always required
  },
  'Last Name': {
    label: 'Last Name',
    description: 'Primary borrower last name',
    section: 'borrower',
    subSection: 'personal-info',
    severity: 'error',
    path: 'last_name',
    helpText: 'Last name is required for all applications',
    requiredCondition: () => true, // Always required
  },
  // New mapping for borrower alias
  Alias: {
    label: 'Alias',
    description: 'Primary borrower alias',
    section: 'borrower',
    subSection: 'personal-info',
    severity: 'info',
    path: 'name_aliases',
    helpText: 'Alias can be used as an alternative name for identification',
    requiredCondition: () => false, // Not required
  },
  'Date of Birth': {
    label: 'Date of Birth',
    description: 'Primary borrower date of birth',
    section: 'borrower',
    subSection: 'personal-info',
    severity: 'error',
    path: 'date_of_birth',
    helpText: 'Date of birth is required for all applications',
    requiredCondition: () => true, // Always required
  },
  'First Time Buyer': {
    label: 'First Time Home Buyer',
    description: 'First time home buyer status',
    section: 'borrower',
    subSection: 'personal-info',
    severity: 'warning',
    path: 'first_time_home_buyer',
    requiredCondition: (data) =>
      data.loan_purpose?.toLowerCase().includes('purchase'),
    helpText: 'Required for purchase loans',
  },

  // Contact Info
  Email: {
    label: 'Email Address',
    description: 'Primary borrower email',
    section: 'borrower',
    subSection: 'contact-info',
    severity: 'error',
    path: 'email',
    helpText: 'Valid email address is required',
    requiredCondition: () => true, // Always required
  },
  Phone: {
    label: 'Phone Number',
    description: 'Primary borrower phone',
    section: 'borrower',
    subSection: 'contact-info',
    severity: 'error',
    path: 'phone',
    helpText: 'Valid phone number is required',
    requiredCondition: () => true, // Always required
  },

  // Citizenship
  'Citizenship Status': {
    label: 'Citizenship Status',
    description: 'Borrower citizenship status',
    section: 'borrower',
    subSection: 'citizenship',
    severity: 'warning',
    path: 'declaration_citizen_status',
    helpText: 'Required for all borrowers',
    requiredCondition: () => false, // Required, but handled by presence check in service
  },
  'Country of Citizenship': {
    label: 'Country of Citizenship',
    description: 'Country of citizenship for non-US citizens',
    section: 'borrower',
    subSection: 'citizenship',
    severity: 'warning',
    path: 'country_of_citizenship',
    requiredCondition: (data) => data.declaration_citizen === false,
    helpText: 'Required for all non-US citizens',
  },

  // Military Status
  'Military Service': {
    label: 'Military Service',
    description: 'Military service status',
    section: 'borrower',
    subSection: 'military-status',
    severity: 'info',
    path: 'military_service_member',
    helpText: 'Indicates if borrower has military service history',
    requiredCondition: () => false, // Not required
  },
  'Military Details': {
    label: 'Military Details',
    description: 'Military service details',
    section: 'borrower',
    subSection: 'military-status',
    severity: 'warning',
    path: 'military_details',
    requiredCondition: (data) => data.declaration_military === true, // Corrected condition
    helpText: 'Required if borrower has military service',
  },
  // New mapping for Military Tour Date
  'Military Tour Date': {
    label: 'Military Tour Date',
    description: 'Date of military tour',
    section: 'borrower',
    subSection: 'military-status',
    severity: 'warning',
    path: 'military_service_tour_date',
    helpText: 'Required if military service includes active duty tours',
    requiredCondition: (data) => data.military_details?.includes('Active'),
  },

  // Family Details
  'Marital Status': {
    label: 'Marital Status',
    description: 'Borrower marital status',
    section: 'borrower',
    subSection: 'family-details',
    severity: 'warning',
    path: 'marital_status',
    helpText: 'Important for title and property rights',
    requiredCondition: () => false, // Required, but handled by presence check
  },
  'Legal Spouse': {
    label: 'Legal Spouse Information',
    description: 'Legal spouse property rights',
    section: 'borrower',
    subSection: 'family-details',
    severity: 'warning',
    path: 'non_spouse_property_rights',
    requiredCondition: (data) => data.marital_status === 'Married',
    helpText: 'Required for married borrowers',
  },
  Dependents: {
    label: 'Dependents',
    description: 'Number of dependents',
    section: 'borrower',
    subSection: 'family-details',
    severity: 'info',
    path: 'declaration_dependents',
    helpText: 'Important for debt-to-income calculations',
    requiredCondition: () => false, // Required, but handled by presence check
  },
  // New mapping for Family Member field
  'Family Member': {
    label: 'Family Member',
    description: 'Indicates relation with seller for family members',
    section: 'borrower',
    subSection: 'family-details',
    severity: 'info',
    path: 'declaration_relation_with_seller',
    helpText: 'Family member information can affect eligibility',
    requiredCondition: () => false, // Required, but handled by presence check
  },
  // Consolidated mapping for Forbearance (used in both Family and Loan sections)
  Forbearance: {
    label: 'Forbearance',
    description: 'Forbearance information is required',
    section: 'loan-details',
    subSection: 'loan',
    severity: 'error',
    helpText: 'Forbearance details should be provided when applicable',
    requiredCondition: (data) => data.isRefi, // Required for refi
  },

  // Addresses
  // New mapping for Residence History Entry
  'Residence History Entry': {
    label: 'Residence History',
    description: 'Residence history entry is required',
    section: 'addresses',
    subSection: 'residence-history',
    severity: 'warning',
    helpText: 'At least one residence entry must be provided',
    requiredCondition: () => false, // Required, but handled by presence check
  },
  // New mapping for Current Residence
  'Current Residence': {
    label: 'Current Residence',
    description: 'Current residence must be present',
    section: 'addresses',
    subSection: 'residence-history',
    severity: 'error',
    helpText: 'The current residence information is mandatory',
    requiredCondition: () => true, // Always required
  },
  // New mapping for Two Years History
  'Two Years History': {
    label: 'Two Years History',
    description: 'At least two years of residency history is required',
    section: 'addresses',
    subSection: 'residence-history',
    severity: 'warning',
    helpText: 'Residency should cover at least two years',
    requiredCondition: () => false, // Required, but handled by presence/complex logic
  },
  // New mapping for Mailing Address
  'Mailing Address': {
    label: 'Mailing Address',
    description: 'Mailing address must be provided',
    section: 'addresses',
    subSection: 'mailing-addresses',
    severity: 'warning',
    helpText: 'Please verify mailing address details',
    requiredCondition: () => false, // Required, but handled by presence check
  },

  // Additional Properties
  // New mapping for Property Entries
  'Property Entries': {
    label: 'Property Entries',
    description: 'Property entries must be provided',
    section: 'owned-properties',
    severity: 'warning',
    helpText: 'Enter at least one property record',
    requiredCondition: () => false, // Required, but handled by presence check
  },
  // New mapping for Property Details
  'Property Details': {
    label: 'Property Details',
    description: 'Detailed property information is required',
    section: 'owned-properties',
    severity: 'warning',
    helpText: 'Ensure all property details are complete',
    requiredCondition: () => false, // Required, but handled by presence/complex logic
  },
  // New mapping for Investment Property Financials
  'Investment Property Financials': {
    label: 'Investment Property Financials',
    description: 'Financial details for investment properties are required',
    section: 'owned-properties',
    severity: 'warning',
    helpText: 'Provide rental income and expenses information',
    requiredCondition: () => false, // Required, but handled by presence/complex logic
  },
  // New mapping for Current Primary Property
  'Current Primary Property': {
    label: 'Current Primary Property',
    description: 'Identification of the current primary property is required',
    section: 'addresses',
    subSection: 'residence-history',
    severity: 'warning',
    helpText: 'Indicate which property is the primary residence',
    requiredCondition: () => false, // Required, but handled by presence check
  },
  // New mapping for Subject Property
  'Subject Property': {
    label: 'Subject Property',
    description: 'Subject property must be identified',
    section: 'loan-details',
    subSection: 'property',
    severity: 'warning',
    helpText: 'The property being evaluated should be marked as subject',
    requiredCondition: () => false, // Required, but handled by presence check
  },

  // Loan Details - Property
  'Property Type': {
    label: 'Property Type',
    description: 'Type of property',
    section: 'loan-details',
    subSection: 'property',
    severity: 'error',
    path: 'property_type',
    helpText: 'Required for all loans',
    requiredCondition: () => true, // Always required
  },
  'Property Use': {
    label: 'Property Use',
    description: 'Intended use of property',
    section: 'loan-details',
    subSection: 'property',
    severity: 'error',
    path: 'property_use',
    helpText: 'Required for all loans',
    requiredCondition: () => true, // Always required
  },
  // New mapping for Property Location
  'Property Location': {
    label: 'Property Location',
    description: 'Property location is required for validation',
    section: 'loan-details',
    subSection: 'property',
    severity: 'warning',
    helpText: 'Enter the full property address',
    requiredCondition: () => false, // Required, but handled by presence check
  },

  // Loan Details - Loan
  'Loan Purpose': {
    label: 'Loan Purpose',
    description: 'Purpose of the loan',
    section: 'loan-details',
    subSection: 'loan',
    severity: 'error',
    path: 'loan_purpose',
    helpText: 'Required for all loans',
    requiredCondition: () => true, // Always required
  },
  // New mapping for Property Value
  'Property Value': {
    label: 'Property Value',
    description: 'Property value is required for refinance',
    section: 'loan-details',
    subSection: 'loan',
    severity: 'error',
    path: 'property_value',
    helpText: 'Provide the current estimated property value',
    requiredCondition: (data) => data.isRefi,
  },
  // New mapping for Cash Out Amount
  'Cash Out Amount': {
    label: 'Cash Out Amount',
    description: 'Optional cash out amount for purchase/refinance',
    section: 'loan-details',
    subSection: 'loan',
    severity: 'info',
    path: 'cash_out',
    helpText: 'Enter cash out amount if applicable',
    requiredCondition: () => false, // Not required
  },
  // New mapping for Remaining Balance
  'Remaining Balance': {
    label: 'Remaining Balance',
    description: 'Remaining balance is required for refinance',
    section: 'loan-details',
    subSection: 'loan',
    severity: 'error',
    path: 'remaining_balance',
    helpText: 'Provide the current remaining mortgage balance',
    requiredCondition: (data) => data.isRefi,
  },
  // New mapping for Purchase Price
  'Purchase Price': {
    label: 'Purchase Price',
    description: 'Purchase price is required for purchase loans',
    section: 'loan-details',
    subSection: 'loan',
    severity: 'error',
    path: 'property_purchase_price',
    helpText: 'Enter the agreed upon purchase price',
    requiredCondition: (data) => data.isPurchase,
  },
  // New mapping for Down Payment
  'Down Payment': {
    label: 'Down Payment',
    description: 'Down payment is required for purchase loans',
    section: 'loan-details',
    subSection: 'loan',
    severity: 'error',
    path: 'property_down_payment',
    helpText: 'Provide the down payment amount',
    requiredCondition: (data) => data.isPurchase,
  },
  // New mapping for Own Business
  'Own Business': {
    label: 'Own Business',
    description: 'Home business information must be provided',
    section: 'loan-details',
    subSection: 'loan',
    severity: 'warning',
    path: 'home_business',
    helpText: 'State whether the applicant owns a business',
    requiredCondition: () => false, // Required, but handled by presence check
  },

  // Loan Details - AVM
  // New mapping for AVM Run
  'AVM Run': {
    label: 'AVM Run',
    description: 'AVM has not been run',
    section: 'loan-details',
    subSection: 'avm',
    severity: 'error',
    helpText: 'Trigger a re-run of the AVM if missing',
    requiredCondition: (data) => data.hasAddress, // Required if address is present
  },
  // New mapping for Estimated Value
  'Estimated Value': {
    label: 'Estimated Value',
    description: 'AVM estimated value is recommended for refinance',
    section: 'loan-details',
    subSection: 'avm',
    severity: 'warning',
    helpText: 'Include AVM estimated value when available',
    requiredCondition: () => false, // Recommended, but not strictly required
  },
  // New mapping for Year Purchased
  'Year Purchased': {
    label: 'Year Purchased',
    description: 'Year purchased is recommended for refinance',
    section: 'loan-details',
    subSection: 'avm',
    severity: 'warning',
    helpText: 'Provide the year the property was purchased',
    requiredCondition: () => false, // Recommended, but not strictly required
  },
  // New mapping for Year Built
  'Year Built': {
    label: 'Year Built',
    description: 'Year built is recommended for refinance',
    section: 'loan-details',
    subSection: 'avm',
    severity: 'warning',
    helpText: 'Enter the construction year of the property',
    requiredCondition: () => false, // Recommended, but not strictly required
  },

  // Loan Details - Pricing
  // New mapping for Pricing Scenario
  'Pricing Scenario': {
    label: 'Pricing Scenario',
    description: 'Pricing scenario has not been saved',
    section: 'loan-details',
    subSection: 'locked-rate',
    severity: 'warning',
    helpText: 'Select a pricing scenario for the loan',
    requiredCondition: () => false, // Required, but handled by presence check
  },

  // Loan Details - NTB
  // New mapping for Net Tangible Benefit
  'Net Tangible Benefit': {
    label: 'Net Tangible Benefit',
    description: 'NTB is required for this scenario',
    section: 'loan-details',
    subSection: 'net-tangible-benefits',
    severity: 'warning',
    helpText: 'Determine if NTB criteria are met',
    requiredCondition: (data) => data.isVALoan || data.isInCOMD, // Required for VA loans or CO/MD
  },

  // Employment
  // New mapping for Employment History
  'Employment History': {
    label: 'Employment History',
    description: 'Two years of employment is required',
    section: 'employment',
    subSection: 'primary-employment-income',
    severity: 'error',
    helpText: 'Provide employment history covering at least two years',
    requiredCondition: () => false, // Required, but handled by presence/complex logic
  },
  // New mapping for Current Employment
  'Current Employment': {
    label: 'Current Employment',
    description: 'Current employment must be selected',
    section: 'employment',
    subSection: 'primary-employment-income',
    severity: 'error',
    helpText: 'Mark the current employment job',
    requiredCondition: () => false, // Required, but handled by presence check
  },
  // New mapping for Other Income
  'Other Income': {
    label: 'Other Income',
    description: 'Other income information is optional',
    section: 'employment',
    subSection: 'other-income',
    severity: 'info',
    helpText: 'Include any additional income sources',
    requiredCondition: () => false, // Not required
  },

  // Assets
  // New mapping for Asset Entries
  'Asset Entries': {
    label: 'Asset Entries',
    description: 'Asset entries are required for qualification',
    section: 'assets',
    severity: 'warning',
    helpText: 'List all assets available',
    requiredCondition: () => false, // Required, but handled by presence check
  },

  // Liabilities
  // New mapping for Credit Report
  'Credit Report': {
    label: 'Credit Report',
    description: 'Credit report has not been run',
    section: 'liabilities',
    subSection: 'credit-report-liabilities',
    severity: 'error',
    helpText: 'A credit report is required',
    requiredCondition: () => false, // Required, but handled by presence check
  },
  // New mapping for Co-Borrower Credit Report
  'Co-Borrower Credit Report': {
    label: 'Co-Borrower Credit Report',
    description: 'Co-borrower credit report has not been run',
    section: 'liabilities',
    subSection: 'credit-report-liabilities',
    severity: 'error',
    helpText: 'A credit report is required for the co-borrower',
    requiredCondition: (data) => Boolean(data.hasCoBorrower), // Required if co-borrower exists
  },
  // New mapping for Other Liabilities
  'Other Liabilities': {
    label: 'Other Liabilities',
    description: 'Other liabilities information',
    section: 'liabilities',
    subSection: 'other-liabilities',
    severity: 'info',
    helpText: 'List any additional liabilities',
    requiredCondition: () => false, // Not required
  },

  // Declarations & Demographics
  // New mapping for Borrower Declarations
  'Borrower Declarations': {
    label: 'Borrower Declarations',
    description: 'All borrower declarations must be completed',
    section: 'decs-and-dems',
    subSection: 'borrower-declarations',
    severity: 'error',
    path: 'borrowerDeclarations',
    helpText: 'Ensure all declaration fields are filled',
    requiredCondition: () => true, // Always required (handled by complex logic in service)
  },
  // New mapping for Borrower Demographics
  'Borrower Demographics': {
    label: 'Borrower Demographics',
    description: 'Demographics information must be completed',
    section: 'decs-and-dems',
    subSection: 'borrower-declarations',
    severity: 'error',
    path: 'borrowerDemographics',
    helpText: 'Complete all demographic details',
    requiredCondition: () => true, // Always required (handled by complex logic in service)
  },

  // Co-Borrower Personal Info
  'Co-Borrower First Name': {
    label: 'Co-Borrower First Name',
    description: 'Co-borrower first name is required',
    section: 'coborrower',
    subSection: 'personal-info',
    severity: 'error',
    path: 'co.first_name',
    helpText: 'Enter the first name of the co-borrower',
    requiredCondition: () => true, // Always required
  },
  'Co-Borrower Last Name': {
    label: 'Co-Borrower Last Name',
    description: 'Co-borrower last name is required',
    section: 'coborrower',
    subSection: 'personal-info',
    severity: 'error',
    path: 'co.last_name',
    helpText: 'Enter the last name of the co-borrower',
    requiredCondition: () => true, // Always required
  },
  'Co-Borrower Alias': {
    label: 'Co-Borrower Alias',
    description: 'Co-borrower alias',
    section: 'coborrower',
    subSection: 'personal-info',
    severity: 'info',
    path: 'co.name_aliases',
    helpText: 'Optional alias for the co-borrower',
    requiredCondition: () => false, // Not required
  },
  'Co-Borrower Date of Birth': {
    label: 'Co-Borrower Date of Birth',
    description: 'Co-borrower date of birth is required',
    section: 'coborrower',
    subSection: 'personal-info',
    severity: 'error',
    path: 'co.date_of_birth',
    helpText: 'Provide the date of birth for the co-borrower',
    requiredCondition: () => true, // Always required
  },

  // Co-Borrower Contact Info
  'Co-Borrower Email': {
    label: 'Co-Borrower Email',
    description: 'Co-borrower email is required',
    section: 'coborrower',
    subSection: 'contact-info',
    severity: 'error',
    path: 'co.email',
    helpText: 'Enter a valid email for the co-borrower',
    requiredCondition: () => true, // Always required
  },
  'Co-Borrower Phone': {
    label: 'Co-Borrower Phone',
    description: 'Co-borrower phone is required',
    section: 'coborrower',
    subSection: 'contact-info',
    severity: 'error',
    path: 'co.phone',
    helpText: 'Enter a contact phone number for the co-borrower',
    requiredCondition: () => true, // Always required
  },

  // Co-Borrower Citizenship
  'Co-Borrower Citizenship Status': {
    label: 'Co-Borrower Citizenship Status',
    description: 'Co-borrower citizenship status',
    section: 'coborrower',
    subSection: 'citizenship',
    severity: 'warning',
    path: 'co.declaration_citizen_status',
    helpText: 'Required for all co-borrowers',
    requiredCondition: () => false, // Required, but handled by presence check
  },
  'Co-Borrower Country of Citizenship': {
    label: 'Co-Borrower Country of Citizenship',
    description: 'Country of citizenship for the co-borrower',
    section: 'coborrower',
    subSection: 'citizenship',
    severity: 'warning',
    path: 'co.country_of_citizenship',
    helpText: 'Required for all non-US citizen co-borrowers',
    requiredCondition: (data) =>
      data.co?.declaration_citizen_status === 'PermanentResidentAlien' ||
      data.co?.declaration_citizen_status === 'NonPermanentResidentAlien',
  },

  // Co-Borrower Military Status
  'Co-Borrower Military Service': {
    label: 'Co-Borrower Military Service',
    description: 'Co-borrower military service status',
    section: 'coborrower',
    subSection: 'military-status',
    severity: 'info',
    path: 'co.declaration_military',
    helpText: 'Indicates if the co-borrower has military service',
    requiredCondition: () => false, // Not required
  },
  'Co-Borrower Military Details': {
    label: 'Co-Borrower Military Details',
    description: 'Co-borrower military details',
    section: 'coborrower',
    subSection: 'military-status',
    severity: 'warning',
    path: 'co.military_details',
    helpText: 'Provide details if the co-borrower has military service',
    requiredCondition: (data) => Boolean(data.co?.declaration_military), // Corrected condition
  },
  'Co-Borrower Military Tour Date': {
    label: 'Co-Borrower Military Tour Date',
    description: 'Co-borrower military tour date',
    section: 'coborrower',
    subSection: 'military-status',
    severity: 'warning',
    path: 'co.military_service_tour_date',
    helpText: 'Enter the tour date if applicable',
    requiredCondition: (data) => data.co?.military_details?.includes('Active'),
  },

  // Co-Borrower Family Details
  'Co-Borrower Marital Status': {
    label: 'Co-Borrower Marital Status',
    description: 'Co-borrower marital status',
    section: 'coborrower',
    subSection: 'family-details',
    severity: 'warning',
    path: 'co.marital_status',
    helpText: 'State the marital status of the co-borrower',
    requiredCondition: () => false, // Required, but handled by presence check
  },
  'Co-Borrower Relation to Primary': {
    label: 'Co-Borrower Relation to Primary',
    description:
      'Relation to primary borrower is required for married co-borrowers',
    section: 'coborrower',
    subSection: 'family-details',
    severity: 'error',
    path: 'co.married_to_primary',
    helpText: 'Specify the relationship with the primary borrower',
    requiredCondition: (data) => data.co?.marital_status === 'Married',
  },
  'Co-Borrower Needed to Qualify': {
    label: 'Co-Borrower Needed to Qualify',
    description: 'Indicates if co-borrower is needed to qualify',
    section: 'coborrower',
    subSection: 'finances',
    severity: 'info',
    path: 'co.finances_used_to_qualify',
    helpText: 'Mark if the co-borrower is required for the application',
    requiredCondition: () => false, // Not required
  },

  // Co-Borrower Declarations & Demographics (using the same section as borrower)
  'Co-Borrower Declarations': {
    label: 'Co-Borrower Declarations',
    description: 'All co-borrower declarations must be completed',
    section: 'decs-and-dems',
    subSection: 'coBorrower-declarations',
    severity: 'error',
    path: 'co.coBorrowerDeclarations',
    helpText: 'Ensure all co-borrower declaration fields are filled',
    requiredCondition: () => true, // Always required (handled by complex logic)
  },
  'Co-Borrower Demographics': {
    label: 'Co-Borrower Demographics',
    description: 'Co-borrower demographics information must be completed',
    section: 'decs-and-dems',
    subSection: 'coBorrower-declarations',
    severity: 'error',
    path: 'co.coBorrowerDemographics',
    helpText: 'Complete co-borrower demographic details',
    requiredCondition: () => true, // Always required (handled by complex logic)
  },
} as const
