import incense from '../services/error-handling/incense'
import http from '../services/http'
import { environment } from '../../environments/environment'
import { CustomError } from '@mortgage-pos/error-handling'

export const sendRateZipTracking = async (
  applicationId: number,
  loanPurpose: string,
  searchParams: URLSearchParams
): Promise<void> => {
  const isRefinance = loanPurpose.toLowerCase() === 'refinance'

  if (!applicationId || !loanPurpose || !searchParams) {
    throw new CustomError('sendRateZipTracking', 'Invalid arguments', 400)
  }

  const params = new URLSearchParams({
    a: 'sage',
    p: isRefinance ? 'refinance' : 'purchase',
    s1: searchParams.get('s1') || '',
    s2: searchParams.get('s2') || '',
    l: applicationId.toString(),
    r: isRefinance ? '45' : '50',
  })

  try {
    await http.post(
      `${environment.bankradarPixelUrl}/pixel?${params.toString()}`,
      {
        body: null,
      }
    )
  } catch (error) {
    incense(error)
      .details({
        name: 'RateZipTrackingError',
        message: 'Failed to send RateZip tracking request',
      })
      .safe({ applicationId })
      .sensitive({
        loanPurpose,
        params: params.toString(),
      })
      .error()

    // Silent fail - don't block the flow if tracking fails
  }
}
