import Save from '@mortgage-pos/ui/assets/save-icon.svg'
import Trees from '@mortgage-pos/ui/assets/houses_compare.png'
import sageHomeLoansLogo from '@mortgage-pos/ui/assets/sageHomeLoansLogo.svg'
import sageHomeLoansLogoWhite from '@mortgage-pos/ui/assets/sageHomeLoansLogoWhite.svg'
import TimeIcon from '@mortgage-pos/ui/assets/sage/7MIN_ICON.png'
import MoneyIcon from '@mortgage-pos/ui/assets/sage/$430_ICON.png'
import AmazonLoader from '@mortgage-pos/ui/assets/amazon-loader.svg'
import HelpfulIcon from '@mortgage-pos/ui/assets/sage-helpful-icon.png'
import FacebookIcon from '@mortgage-pos/ui/assets/sage/FacebookIcon.png'
import LinkedInIcon from '@mortgage-pos/ui/assets/sage/LinkedInIcon.png'
import InstagramIcon from '@mortgage-pos/ui/assets/sage/InstagramIcon.png'

import SageLoaderChecklist from '@mortgage-pos/ui/assets/sage/SageLoaderChecklist.gif'
import Summary_Credit_Icon from '@mortgage-pos/ui/assets/Summary_Credit_Icon.svg'
import Summary_Declarations_Icon from '@mortgage-pos/ui/assets/Summary_Declarations_Icon.svg'
import Summary_Personal_Icon from '@mortgage-pos/ui/assets/Summary_Personal_Icon.svg'
import Summary_Property_Icon from '@mortgage-pos/ui/assets/Summary_Property_Icon.svg'
import Equity_Icon from '@mortgage-pos/ui/assets/Equity_Icon.svg'

import Customization, {
  IconMap,
  CustomTextMap,
  LoaderMap,
  LogoMap,
} from '../../../types/customization'

const logos: LogoMap = {
  default: sageHomeLoansLogo,
  color: {
    white: sageHomeLoansLogoWhite,
  },
}

const loaders: LoaderMap = {
  default: AmazonLoader,
  house: AmazonLoader,
  checklist: SageLoaderChecklist,
}

// TODO: Will need new text from business to replace these
const customText: CustomTextMap = {
  displayName: 'Sage Home Loans Corporation',
  sageFullPartnerName: 'Sage Home Loans Corporation',
  sageBaseUrl: 'sagehomeloans.com',
  valueProp1: {
    title: 'Access your equity',
    description: "We've helped our customers access $200M in home equity",
  },
  valueProp2: {
    title: '15 seconds',
    description:
      'How long it’ll take you to see your custom comparison options',
  },
}

const icons: IconMap = {
  money: MoneyIcon,
  time: TimeIcon,
  facebook: FacebookIcon,
  instagram: InstagramIcon,
  linkedIn: LinkedInIcon,
  footer: Trees,
  save: Save,
  helpful: HelpfulIcon,
  creditIcon: Summary_Credit_Icon,
  declarationsIcon: Summary_Declarations_Icon,
  personalIcon: Summary_Personal_Icon,
  propertyIcon: Summary_Property_Icon,
  equityIcon: Equity_Icon,
}

export default {
  logos,
  loaders,
  customText,
  icons,
} as Customization
