export enum QuestionType {
  Radio = 'Question-Radio',
  Input = 'Question-Input',
  Select = 'Question-Select',
  MultiSelectButton = 'Question-MultiSelectButton',
  Hidden = 'Question-Hidden',
  Income = 'Question-Income',
  Checkbox = 'Question-Checkbox',
  StateSelect = 'Question-StateSelect',
  MultiButton = 'Question-MultiButton',
  Demographics = 'Question-Demographics',
  BinaryButton = 'Question-BinaryButton',
  CheckboxMulti = 'Question-CheckboxMulti',
  LanguagePreference = 'Question-Language',
  QuestionGroup = 'Question-QuestionGroup',
  CreateAccount = 'Question-CreateAccount',
  RateSelection = 'Question-RateSelection',
  NTLRateSelection = 'Question-NTLRateSelection',
  CompareLERateSelection = 'Question-CompareLERateSelection',
  AddressAutoComplete = 'Question-AddressAutoComplete',
  AddressFieldsAutoComplete = 'Question-AddressFieldsAutoComplete',
  RepeatableQuestionGroup = 'Question-RepeatableQuestionGroup',
  APICallLoader = 'Question-APICallLoader',
  Uploader = 'Question-Uploader',
  InfoAccordion = 'Question-InfoAccordion',
  SSN = 'Question-SSN',
  PlaidAssets = 'Question-PlaidAssets',
  AmazonBenefits = 'Question-AmazonBenefits',
  Toggle = 'Question-Toggle',
}
