export enum ContactStatus {
  Empty = '',
  Contacted = 'Contacted',
  NotContacted = 'Not Contacted',
  ContactAttemptedEmail = 'Contact Attempted - Emailed',
  ContactAttemptedLeftVM = 'Contact Attempted - Left VM',
  ContactAttemptedNoAction = 'Contact Attempted - No Action',
  ContactAttemptedTexted = 'Contact Attempted - Texted',
  ContactedFollowUpScheduled = 'Contacted - Follow Up Scheduled',
  ContactedHotLead = 'Contacted - Hot Lead',
  ContactedNurture = 'Contacted - Nurture',
  ContactedTextEmail = 'Contacted - Text/Email',
  Locked = 'Locked',
  NewCalendlyScheduled = 'New - Calendly Scheduled',
  New = 'New',
  PreApprovedCOPA = 'Pre-approved COPA',
  PreApprovedVPA = 'Pre-approved VPA',
  ConditionsRequested = 'Conditions Requested',
  DoNotCall = 'DO NOT CALL',
  DoesNotQualify = 'Does Not Qualify',
}
