import { LeadSourceType } from '@mortgage-pos/types'

// For new sources, add a new object to the map where the the key is the "LeadSource" string
// being passed alongside a "LeadSourceId"
// - name: this is the string that will be stored in the "source" column on the Applications table
// - trackingField (optional): this is the name of the property to store the Id value as in Application_Tracking
// - sourceField (optional): legacy BankRate request handler field name to find in request data
type LeadSourceMap = {
  [s in LeadSourceType]: LeadSource
}

export const leadSourceMap: Partial<LeadSourceMap> = {
  ALFALFA: {
    name: 'Alfalfa',
  },
  AMAZON: {
    name: 'Amazon',
    urlMatchString: 'src=2f49875e',
  },
  AMAZONBENEFITSDEMO: {
    name: 'AmazonBenefitsDemo',
  },
  BANKRATE: {
    name: 'Bankrate',
    trackingField: 'bankrateLeadId',
    sourceField: 'bankrateLeadId',
  },
  BANKRATEDIGIFLEX: {
    name: 'BankrateDigiFlex',
    trackingField: 'bankrateLeadId',
    sourceField: 'bankrateLeadId',
  },
  BANKRATECPC: {
    name: 'BankrateCPC',
    urlMatchString: 'cpc=true',
  },
  BANKRATEEMAIL: {
    name: 'BankrateEmail',
    sourceField: 'bankrateLeadId',
    trackingField: 'bankrateLeadId',
    urlMatchString: 'source=BankrateEmail',
  },
  BANKRATEPRIMARY: {
    name: 'BankratePrimary',
    sourceField: 'bankrateLeadId',
    trackingField: 'bankrateLeadId',
  },
  BANKRATEPRO: {
    name: 'BankratePro',
    sourceField: 'bankrateLeadId',
    trackingField: 'bankrateLeadId',
    urlMatchString: 'pro.bankrate.com',
    urlSecondaryMatchString: 'pro.qa.bankrate.com',
  },
  BANKRATESECONDARY: {
    name: 'BankrateSecondary',
    sourceField: 'bankrateLeadId',
    trackingField: 'bankrateLeadId',
  },
  BANKRATEALFIE: {
    name: 'BankrateAlfie',
  },
  BANKRATEFEDEMAIL: {
    name: 'BankrateFedEmail',
    urlMatchString: 'utm_campaign=ed_ho_bn_fed_pre',
  },
  BANKRATECASHOUTEMAIL: {
    name: 'BankrateCashOutEmail',
    urlMatchString: 'src=br439',
  },
  BACKFILL: {
    name: 'Backfill',
  },
  BING: {
    name: 'Bing',
    urlMatchString: 'utm_source=bing',
  },
  CALENDLYAPPOINTMENT: {
    name: 'CalendlyAppointment',
  },
  CONSUMERAFFAIRS: {
    name: 'ConsumerAffairs',
    urlMatchString: 'source=consumeraffairs',
  },
  CPC: {
    name: 'CPC',
    urlMatchString: 'click_id=',
  },
  CUSTOMERREFERRAL: {
    name: 'CustomerReferral',
  },
  EMPLOYEELOANS: {
    name: 'EmployeeLoans',
  },
  EXISTINGCUSTOMERREFERRAL: {
    name: 'ExistingCustomerReferral',
  },
  FACEBOOK: {
    name: 'Facebook',
    trackingField: 'fbclid',
    sourceField: 'fBLeadId',
  },
  FACET: {
    name: 'Facet',
    urlMatchString: 'facet',
  },
  FED: {
    name: 'Fed',
    urlMatchString: 'src=6rlkye84',
  },
  FREERATEUPDATE: {
    name: 'FreeRateUpdate',
    trackingField: 'freerateupdateLeadId',
  },
  HARRISTEETER: {
    name: 'HarrisTeeter',
    urlMatchString: 'src=harristeeter',
  },
  HOMEEQUITYCPC: {
    name: 'HomeEquityCPC',
    sourceField: 'bankrateLeadId',
    trackingField: 'bankrateLeadId',
    urlMatchString: 'source=bankratehomeequity',
  },
  HOMEEQUITYPRIMARY: {
    name: 'HomeEquityPrimary',
    sourceField: 'bankrateLeadId',
    trackingField: 'bankrateLeadId',
    urlMatchString: 'pid=br_he_rt_co',
  },
  HOMEEQUITYSECONDARY: {
    name: 'HomeEquitySecondary',
    sourceField: 'bankrateLeadId',
    trackingField: 'bankrateLeadId',
    urlMatchString: 'source=bruno:home-equity:quote',
  },
  HOMELIGHT: {
    name: 'HomeLight',
  },
  HSH: {
    name: 'HSH',
    urlMatchString: 'source=HSH',
  },
  INBOUNDCALL: {
    name: 'InboundCall',
  },
  PROPERTYMATE: {
    name: 'PropertyMate',
  },
  PURCHASEREFERRALS: {
    name: 'PurchaseReferrals',
  },
  LENDINGTREE: {
    name: 'LendingTree',
  },
  LOANESTIMATECOMPARISON: {
    name: 'LoanEstimateComparison',
  },
  LOWERMYBILLS: {
    name: 'LowerMyBills',
    urlMatchString: 'source=LowerMyBills',
  },
  LOWERMYBILLSSHORTFORM: {
    name: 'LowerMyBillsShortForm',
  },
  MYAGENTFINDER: {
    name: 'MyAgentFinder',
    urlMatchString: 'source=MyAgentFinder',
  },
  PERSONALREFERRAL: {
    name: 'PersonalReferral',
  },
  RATEZIP: {
    name: 'RateZip',
    urlMatchParams: ['click_id=', 'prop_id=', '48731'],
  },
  REALTORREFERRAL: {
    name: 'RealtorReferral',
  },
  REFILY: {
    name: 'Refily',
  },
  REPORTING: {
    name: 'Reporting',
  },
  RVMN: {
    name: 'RVMN',
    urlMatchParams: ['click_id=', 'prop_id='],
  },
  SAGEMORTGAGEEMAIL: {
    name: 'SageMortgageEmail',
  },
  SAGERADIO: {
    name: 'Sage Radio',
  },
  SAGEWEBSITE: {
    name: 'Sage Website',
  },
  SEM: {
    name: 'SEM',
    trackingField: 'gclid',
    sourceField: 'gclid',
    urlMatchString: 'utm_source=google',
  },
  SMARTASSET: {
    name: 'SmartAsset',
    urlMatchString: 'sa_mortgage',
  },
  SUPPORTPHONENUMBER: {
    name: 'SupportPhoneNumber',
  },
  TOP10: {
    name: 'Top10',
    urlMatchString: 'source=top10',
  },
  TRANSUNION: {
    name: 'TransUnion',
    trackingField: 'tuLeadOfferCode',
  },
  UNMAPPED: {
    name: 'Unmapped',
    trackingField: 'unmappedLeadId',
  },
  ZILLOW: {
    name: 'Zillow',
    trackingField: 'zillowLeadId',
  },
}

/**
 *
 * @returns "name" in StudlyCaps/UpperCamelCase
 */
export function getBankrateLeadSource(
  offerTags: string[],
  isPrimaryLead?: boolean,
  landingUrl?: string,
  leadSource?: string
): LeadSource {
  if (leadSource?.toUpperCase().includes(LeadSourceType.Amazon)) {
    return leadSourceMap.AMAZON
  }
  // BankrateEmail leads do not have a bankrateLeadId, so they get picked up via the generic url match logic
  // BankrateHomeEquity leads only have a bankRateLeadId, so also check via url here
  if (isPrimaryLead === true) {
    if (landingUrl?.includes(leadSourceMap.HOMEEQUITYPRIMARY.urlMatchString)) {
      return leadSourceMap.HOMEEQUITYPRIMARY
    }

    if (
      leadSource?.toUpperCase() === LeadSourceType.BankratePro ||
      leadSource?.toUpperCase().includes(LeadSourceType.Puma)
    ) {
      return leadSourceMap.BANKRATEPRO
    }

    return leadSourceMap.BANKRATEPRIMARY
  }
  if (offerTags && offerTags.includes('digiFlex')) {
    return leadSourceMap.BANKRATEDIGIFLEX
  }

  if (landingUrl) {
    const landingUrlSource = getSourceByLandingUrl(String(landingUrl))
    if (
      landingUrlSource?.name &&
      ['Bankrate', 'HomeEquity', 'BankratePro'].some((s) =>
        landingUrlSource?.name.includes(s)
      )
    ) {
      return landingUrlSource
    }
  }

  // (isPrimaryLead === false) || NULL
  return leadSourceMap.BANKRATESECONDARY
}

export function getLeadSource(leadSourceName: string): LeadSource {
  // Match to map by key, trimmed & case-insensitive
  if (leadSourceName.trim().toUpperCase() in leadSourceMap) {
    return leadSourceMap[leadSourceName.trim().toUpperCase()]
  }
  return leadSourceMap[LeadSourceType.Unmapped]
}

export function getSource(sourceData: SourceData) {
  // Match to map by the source data containing a field with a name matching "sourceField"
  for (const key in leadSourceMap) {
    if (sourceData[leadSourceMap[key].sourceField]) {
      return leadSourceMap[key].name
    }
  }

  return leadSourceMap[LeadSourceType.SageWebsite].name
}

export function getSourceByLandingUrl(landingUrl: string) {
  const strictMatches = Object.keys(leadSourceMap).reduce((acc, key) => {
    if (typeof leadSourceMap[key].urlMatchParams !== 'undefined') {
      acc.push(key)
    }
    return acc
  }, [])

  // Sort by the number of parameters in the urlMatchParams array
  // in order of most parameters to least to match on stricter sources first
  // (i.e. RVMN has multiple related sources and is treated as the fall back)
  strictMatches.sort((a, b) => {
    const aLength = leadSourceMap[a].urlMatchParams?.length || 0
    const bLength = leadSourceMap[b].urlMatchParams?.length || 0
    return bLength - aLength
  })

  // Check for multiple parameter matches first
  for (const key of strictMatches) {
    if (
      leadSourceMap[key].urlMatchParams.every((param) =>
        landingUrl.includes(param)
      )
    ) {
      return leadSourceMap[key]
    }
  }

  for (const key in leadSourceMap) {
    if (
      typeof leadSourceMap[key].urlMatchString !== 'undefined' &&
      landingUrl.includes(leadSourceMap[key].urlMatchString)
    ) {
      return leadSourceMap[key]
    }

    if (
      typeof leadSourceMap[key].urlSecondaryMatchString !== 'undefined' &&
      landingUrl.includes(leadSourceMap[key].urlSecondaryMatchString)
    ) {
      return leadSourceMap[key]
    }
  }
}

export type LeadSource = {
  name: string
  trackingField?: string
  sourceField?: string
  urlMatchString?: string
  urlSecondaryMatchString?: string
  urlMatchParams?: string[]
}

type SourceData = {
  fBLeadId?
  bankrateLeadId?
  gclid?
}
